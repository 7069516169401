@font-face {
  font-family: Futura-Bold;
  src: url("../../Fonts/Futura_Bold_font.ttf");
}

@font-face {
  font-family: Futura Md BT;
  src: url("../../Fonts/futura_medium_bt.ttf");
}

@font-face {
  font-family: Futura Bk BT;
  src: url("../../Fonts/Futura\ Book\ font.ttf");
}

@font-face {
  font-family: Futura Hv BT;
  src: url("../../Fonts/Futura_Heavy_font.ttf");
}

@font-face {
  font-family: Futura Lt BT;
  src: url("../../Fonts/futura_light_bt.ttf");
}

.container {
  width: 100%;
  height: 100px;
  background-color: var(--PRIMARY-COLOR);
  display: flex;

  @media (max-width: 1150px) {
    height: 150px;
  }
}

.logoContainer {
  width: 40%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: skew(-50deg);
  margin-left: -100px;

  @media (max-width: 1400px) {
    width: 50%;
  }
  @media (max-width: 1100px) {
    width: 60%;
  }

  @media (max-width: 700px) {
    width: 100%;
    transform: skew(0deg);
    margin-left: 0px;
  }
}

.menuContainer {
  width: 100%;
  height: 50px;
  background-color: gray;
  display: flex;

  @media (max-width: 750px) {
    display: none;
  }
}

.menuBox {
  width: 70%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transform: skew(50deg);
  margin-left: -100px;

  @media (max-width: 1300px) {
    width: 85%;
  }

  @media (max-width: 1200px) {
    align-items: flex-end;
    width: 80%;
  }

  @media (max-width: 1050px) {
    width: 100%;
    transform: skew(0deg);
    margin-left: 0px;
  }
}

.logo {
  width: 220px;
  height: 80px;
  transform: skew(50deg);

  @media (max-width: 1400px) {
    width: 250px;
    height: 100px;
  }

  @media (max-width: 1100px) {
    width: 180px;
    height: 80px;
  }

  @media (max-width: 700px) {
    transform: skew(0deg);
  }
}

.leftContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    display: none;
  }
}

.leftContent {
  width: 80%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1400px) {
    width: 90%;
  }

  @media (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
  }
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 25px;
  height: 20px;
}

.callIcon {
  width: 35px;
  height: 25px;
}

.boxText {
  color: white;
  font-size: 15px;
  margin-left: 10px;
}

.ParentKeyword {
  position: absolute;
  top: 35px;
  width: 100%;
  padding: 5px 20px;
  background-color: #fff;
  border-bottom: 1px solid #000;
  }

.inputBox {
  width: 180px;
  border-bottom: 1px solid white;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.input {
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
  font-size: 13px;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: white;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: white;
  }

  &::-ms-input-placeholder {
    /* IE 10+ */
    color: white;
  }

  &::-moz-placeholder {
    /* Firefox 18- */
    color: white;
  }
}

.searchIcon {
  width: 15px;
  height: 15px;
  color: white;
}

.menuItem {
  font-size: 16px;
  color: black;

  @media (max-width: 1200px) {
    margin-left: 20px;
    margin-right: 10px;
  }
}

.menuContainerBox {
  width: 70%;
  transform: skew(-50deg);
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 1400px) {
    width: 80%;
  }

  @media (max-width: 1200px) {
    width: 95%;
    justify-content: flex-end;
  }

  @media (max-width: 1050px) {
    transform: skew(0deg);
    justify-content: center;
  }
}

.mobileMenuContainer {
  width: 95%;
  display: none;
  align-items: flex-end;
  justify-content: center;
  margin-top: 15px;

  @media (max-width: 750px) {
    display: flex;
    flex-direction: column;
  }
}

.menuIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-bottom: 10px;
}

.dropDown {
  width: 100%;
  animation: growDown 300ms ease-in-out forwards;
  display: none;

  @media (max-width: 750px) {
    display: flex;
    flex-direction: column;
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
}

.dopDownMenuBox {
  width: 100%;
  padding-left: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.dropdownMenuItem {
  font-size: 16px;
  color: black;
  margin: 0px;
}

a:link, a:visited {
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  
  }


  // .search {
    
  //   display: none;

  // }

  .ParentKeyword a {
    text-align: left !important;
    color: #000 !important;
    padding: 6px 0;
  }

  