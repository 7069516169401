.color {
/* Vector 10 */


position: absolute;
width: 1426px;
height: 1079px;
left: 3px;
top: 2158px;
padding-top: 30px;
margin-bottom: 50px;
background: #2E307B;
border: 2px solid red;
border-top-right-radius: 3500px;


}

.heading {


  /* Why Choose Us */



font-family: Futura-Bold;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 28px;
display: flex;
align-items: center;
letter-spacing: 0.06em;
text-transform: uppercase;
margin: 30px;

color: #7E7E80;

mix-blend-mode: lighten;
}


.mainheading {


/* Delivering Quality Engineered Products. */




font-family: Futura-Bold;
font-style: normal;
font-weight: bold;
font-size: 34px;
line-height: 39px;
display: flex;
align-items: center;
letter-spacing: 0.06em;
margin: 140px;
padding: 20px;

color: #FFFFFF;

}

.para {

  margin: 100px;
  color: #FFFFFF;
  font-weight: bold;
}

.image {
  /* logo-05 1 */
  /* pexels-tiger-lily-4483610 1 */


    /* width: 100%; */
    
    /* position: absolute; */
    /* height: 1070px; */
    top: 2158px;
    
    /* display: block; */
    align-items: center;
    justify-content: flex-start;
    /* margin-bottom: 401px; */
    /* Why Choose Us */


/* position: absolute; */

/* height: 1078px; */

/* padding-top: 245px;
padding-left: 110px; */
font-family: Futura-Bold;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 28px;

align-items: center;
letter-spacing: 0.06em;
text-transform: uppercase;

color: #7E7E80;

mix-blend-mode: lighten;
   
}



.head4 {
/* Delivering Quality Engineered Products. */


/* position: absolute; */
/* width: 846px; */
/* height: 116px; */
left: 145px;
right: 160x;
top: 2476px;
/* margin-left: -34px; */
padding-right: 130px;
width: 50%;
line-height: 50px;
font-family: Futura-Bold;
font-style: normal;
font-weight: bold;
font-size: 34px;
/* line-height: 39px; */
display: flex;
align-items: center;
letter-spacing: 0.06em;

color: #FFFFFF;

}

.visible-desk {
  max-width: 1140px;
  margin: auto;
  padding-top: 9%;
  padding-bottom: 9%;
}

.container5 {
  background-image: url("../../Assets/website-21.jpg");
  background-size: cover;

  background-repeat: no-repeat;
}

.sub-cont {
  position: center !important;
}

.content {


  /* position: absolute; */
  /* width: 846px; */
  /* height: 170px; */
  left: 80px;
  /* line-height: 54px; */
  right: 160x;
  top: 2476px;
  line-height: 22px;
  margin-top: 50px;
  width: 50%;
  /* margin-left: 28px; */
  /* padding-right: 130px; */
  /* margin-top: 90px; */
  font-family: Futura Md BT;
  font-style: normal;

  font-size:  16px;
  line-height: 39px;
  display: flex;
  align-items: center;
  letter-spacing: 0.06em;
  
  color: #FFFFFF;
  font-size: 17px;

  /* margin-top: 250px; */
}


@media screen and (max-width: 1200px) {
  .image {
    /* logo-05 1 */
    /* pexels-tiger-lily-4483610 1 */
  
  
      width: 1200px;
      
      position: absolute;
      height: 800px;
  
      background-image: url("../../Assets/website-21.jpg");
     
      align-items: center;
      margin-bottom: 400px;
      margin-top: 1000px;
      margin-right: 300px;
      /* Why Choose Us */
  
  
  position: absolute;
  
  padding-top: 170px;
  padding-left: 150px;
  font-family: Futura-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  
  align-items: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  
  color: #7E7E80;
  
  mix-blend-mode: lighten;
     
  }
  
}


@media screen and (max-width: 1200px) {
  .head4 {
    /* Delivering Quality Engineered Products. */
    
    
    position: absolute;
    width: 846px;
    height: 116px;
  
    margin-left: 60px;
    padding-right: 130px;
    margin-top: 500px;
    font-family: Futura-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 39px;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    
    color: #FFFFFF;
    
    }

}

.splitscreen-bg {
  background-image: url("../../Assets/website-21.jpg");
  padding: 20%;
  background-size: cover;
  background-position: 10%;
}

.visible-mobile {
  display:none;
}
@media(max-width:768px) {
  .visible-mobile {
      width: 100%;
      display:block;
  }
  .visible-desk {
      display: none;
  }
}

.contentMobile {

  color: white;

   
   margin-top: 50px;
}

.h6 {
  
  color: white;

 
}

.heading-2 {

  color: #7E7E80;
}