.pic {
  /* HEPL PROFILE final-19 1 */

  /* position: absolute; */
  width: auto;
  height: auto;
  left: 150px;
  top: 180px;
  /* margin-top: 3000px; */
}

.head1 {
  /* margin-bottom: 50px; */
  /* position: absolute; */
  width: auto;
  height: auto;
  left: 150px;
  top: 224px;
  /* margin-top: 2800px; */
  font-family: Futura-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 46px;
  display: flex;
  align-items: center;
  letter-spacing: 0.06em;

  color: #2e307b;
}

.button1 {
  /* Rectangle */

  /* position: absolute; */
  width: 288px;
  height: auto;
  left: calc(50% - 288px / 2 - 666px);
  top: calc(50% - 66px / 2 + 427px);
  /* margin-top: 3080px;
margin-right: 50px; */
  background: #2e307b;

  /* position: absolute; */
  /* width: 350px; */
  /* height: 70px; */

  font-family: Futura-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-align: center;
  padding-top: 0;
  margin-top: 20px;
  color: #ffffff;
  mix-blend-mode: normal;
}
a:link,
a:visited {
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.button1 a {
  padding: 20px;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.iso-cert {
  padding: 10px 186px;
}

@media (max-width: 768px) {
  .pic img {
    max-width: 100%;
    height: auto;
  }

  .iso-cert {
    padding: 30px;
  }
}
