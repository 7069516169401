/* .container {
    display: flex;
    flex-direction: column;
    align-items: center;

} */

.row {
  display: flex;
  justify-content: space-around;
}

.parag {
  /* position: absolute; */

  text-align: left;
  margin: 20px auto;

  font-family: Futura Bk BT;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  

  color: #000000;
  margin-left: 25px;
  mix-blend-mode: normal;
}

.img {
  /* logo-05 1 */

  /* padding-right: 700px; */
  /* margin-bottom: 150px; */
  text-align: left;
  margin-left: 30px;
}

.container3 {
  /* position: absolute; */

  /* left: 222px;
top: 3581px;
margin-top: 100px; */

  background: #2e307b;
  /* position: absolute; */
  /* width: 320px;
height: 65px;
left: 325px;
top: 3599px; */

  /* padding-top: 14px; */
  font-family: Futura Md BT;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  text-align: center;

  color: #ffffff;

  mix-blend-mode: normal;
  display: flex;
  width: 85%;
  margin: auto;
  margin-top: 54px;
}

.column1 {
  /* position: absolute; */
  width: 452px;
  /* height: 66px; */
  left: 777px;
  top: 3581px;
  margin-top: 0px;
  /* background-color: #2E307B; */

  /* position: absolute; */
  width: 422px;
  /* height: 60px; */
  left: 790px;
  top: 3602px;
  margin-top: 142px;
  padding-top: 12px;
  font-family: Futura Md BT;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  color: #ffffff;

  mix-blend-mode: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerLink a {
  background-color: #2e307b;
  padding: 20px;
}

.innerLink {
  margin-top: 50%;
}

.heading {
  /* SUBSCRIBE NOW */

  /* position: absolute; */
  width: 221px;
  /* height: 24px;
left: 1428px;
top: 3410px; */
font-family: Futura Hv BT;
font-style: normal;
font-weight: 100;
font-size: 24px;
line-height: 29px;
  /* padding-top: 25px; */
  color: #2e307b;
  margin-bottom: 5px;
  margin-left: 0;

  mix-blend-mode: normal;
}

.input {
  /* Rectangle 97 */

  /* position: absolute; */
  width: 379px;
  height: 65px;
  /* left: 1428px;
     top: 3444px;
     margin-top: 40px; */
  /* margin-left: 30px; */
  border: 1px solid transparent;
  padding-left: 20px;
  font-family: Futura Lt BT;
font-style: italic;
font-weight: 300;
font-size: 20px;
line-height: 24px;

  background: #c4c4c4;
}

.rect {
  /* Rectangle 98 */

  /* position: absolute; */
  width: 270px;
  height: 65px;
  left: 1428px;
  top: 3583px;
  /* margin-top: 90px; */
  background: #2e307b;
  /* SUBMIT NOW */

  /* position: absolute; */
  /* width: 220px;
height: 60px;
left: 1455px;
top: 3603px; */

  font-family: Futura Md BT;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  padding: 20px 27px;
  color: #ffffff;
  mix-blend-mode: normal;
  margin-top: 134px
}

.parag1 {
  /* GET MORE INFO */

  /* position: absolute; */
  width: 213px;
  height: 36px;
  /* left: 1428px;
top: 3658px; */

  font-family: Futura Hv BT;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  margin-top: 10px;
  /* margin-left: 30px; */
  color: #2e307b;
  margin-bottom: 0;

  mix-blend-mode: normal;
}

.image1 {
  /* website-33 1 */

  /* position: absolute; */
  width: 39px;
  /* height: 35px; */
  /* left: 1428px;
top: 3694px; */
  margin-top: 0px;
  /* margin-left: 35px; */
}

.img3 {
  /* website-32 1 */

  /* position: absolute; */
  width: 40px;
  /* height: 35px;
left: 1464px;
top: 3694px; */
  /* margin-top: 111px; */
  margin-left: 5px;
}

@media screen and (max-width: 320px) {
  .parag {
    /* position: absolute; */
    font-size: 5vw;
    margin-top: 70px;
    font-family: Futura Bk BT;

    color: #000000;
  }
}

.img-container {
  padding: 20px 17px;
}

.imagepara {
  /* position: absolute; */
  top: 8px;
  margin-left: 100px;
  margin: 20px 12px 20px 47px;
  padding-left: 0px;
  font-family: Futura Md BT;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
text-align: center;
}

.column2 {
  /* position: absolute; */

  margin-top: 0px;
  /* background: #2E307B; */
  /* position: absolute; */
  width: 422px;
  /* height: 60px; */

  margin-top: 95px;
  padding-top: 12px;
  font-family: Futura Md BT;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  text-align: left;

  color: #ffffff;

  mix-blend-mode: normal;
}

.innerLink {
  font-family: Futura Md BT;
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 29px;
text-align: center;
}

@media (max-width: 768px) {
  .innerLink {
    margin-top: 0%;
  }
  .innerLink a {
    width: 90%;
  }
  .row {
    flex-direction: column;
  }

  .input {
    width: 100%;
  }

  .rect {
    margin-top: 20px;
  }

  .parag1 {
    width: 100%;
    margin-top: 25px;
  }
  .parag {
    text-align: justify;
    margin-left: 0;
  }
  .img {
    margin-left: 0px;
    margin-top: 30px;
  }
  .imagepara {
    margin: 20px 12px 20px 45px;
  }
  .column2 {
    width: 100%;
  }
  .column1 {
    width: 100%;
  }
  .column2 {
    width: 88%;
    margin: auto;
    text-align: center;
  }
  .Home_Flexinner__nIUDB {
    width: 90%;
    margin: auto;
  }
  .Home_para__3_B9W {
    font-size: 22px;

    white-space: nowrap;
  }
  .Home_span__3tjlm {
    white-space: nowrap;
  }
}
