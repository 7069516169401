/* Create two equal columns that floats next to each other */
.column3 {
  margin: 0px;
  width: 50%;
  position: relative;
}

.column4-1 {
  max-width: 50%;
  width: 100%;
  background-image: url("../../Assets/pexels-lex-photography-1109541\ 1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}



.lasthead1 {
  /* ABOUT US */

  /* position: absolute; */
  top: 5px;
  /* margin-top: 4260px; */
  font-family: Futura-Bold;
font-style: normal;
font-weight: bold;
font-size: 35px;
line-height: 40px;
display: flex;
align-items: center;
letter-spacing: 0.06em;
text-transform: uppercase;

  color: #7e7e80;
  /* margin-left: 920px; */
}

.lastpara {
  /* HSE POLICY | Iso certificate| Vision and Mission */

  /* position: absolute; */
  /* margin-top: 4320px;
margin-left: 920px; */
  top: 5px;
  font-family: Futura Lt BT;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  color: #7e7e80;
}

.pic1 {
  /* display: flex; */
}

.para-contain {
  padding: 40px;
  padding-left: 5px;
}

.pic2 {
  /* position: absolute; */
  /* width: auto;
  height: auto; */
  position: relative;
  top: 10%;
  border: 17px solid #fff;
}

.pic3 {
  /* position: absolute; */
  /* width: auto;
  height: auto; */
  left: 47%;
  top: 92%;
  /* margin-left: 340px;
  margin-top: 4960px; */
}

.pic4 {
  /* Rectangle 127 */

  /* position: absolute; */

  /* margin-bottom: 100px; */
  left: 38px;
  /* margin-bottom: 30px; */
  top: 5px;
  /* margin-top: 4880px;
margin-left: 270px; */

  background: #ffffff;
}

.lasthead2 {
  /* Electrical and Mechanical Engineered Custom Solutions. */

  /* position: absolute; */
  width: auto;
  height: auto;
  left: 920px;
  top: 324px;
  /* margin-top: 4160px; */
  font-family: Futura-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 46px;
  display: flex;
  align-items: center;
  letter-spacing: 0.06em;

  color: #2e307b;
}

.lastpara1 {
  /* position: absolute; */
  width: auto;
  height: auto;
  left: 920px;
  top: 449px;
  /* margin-top: 4160px; */
  font-family: Futura Bk BT;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: 0.06em;

  color: #121212;
}

.contain3 {
  margin: auto;
  margin-top: 120px;
 width: 90%;
}

@media (max-width: 768px) {
  .column3 {
    padding: 20px;
    padding-left: 30px;
    margin-bottom: 15%;
    width: 100%;
    margin-top: 40px;
  }

  .pic2 {
    /* left: 7%;
    top: 75%; */
    padding-top: 65%;
    margin-bottom: -40px;
    border: 0;
    text-align: center;

  }

  .column4-1 {
    max-width: 100%;
  }

  .pic1 img{
    max-width: 100%;
  }

  .pic3 {
    left: 43%;
  }

  .contain3 {
    
    padding: 0px;
  }

  .pic2 img {
    width: 75%;
    border: 10px solid #fff;
  }
}
