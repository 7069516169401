* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

/* Float four columns side by side */
.column {
  width: 100%;
  margin: 0 10px;
  background-color: white;
}

/* Remove extra left and right margins, due to padding */
.row {
  margin: 0 0px !important;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

/* Style the counter cards */
/*.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: left;
  background-color: #f1f1f1;
  margin: 0 0 0 8px;
  padding: 60px;

}*/

.heading1 {
  color: #2e307b;
  font-family: Futura Md BT;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 24px;
}
.heading2 {
  padding: 10px;
  color: #2e307b;
}


.j-text{
  text-align: justify;

}

.border {
  /* Vector 8 */

  position: absolute;

  width: 110px;
  border: 1px solid #2e307b;
}

.border1 {
  /* Vector 6 */

  position: absolute;
  width: 110px;
  margin: 10px;

  border: 1px solid #2e307b;
}

.border2 {
  position: absolute;
  width: 110px;
  margin: 10px;

  border: 1px solid #2e307b;
}

.border3 {
  position: absolute;
  width: 110px;
  margin: 10px;

  border: 1px solid #2e307b;
}

.Card1 {
  /* Rectangle 89 


    /*position: absolute;

  left: 147px;
  top: 1409px;*/
  width: 100%;
 
  padding: 10px;


}

.Card2 {
  /* Rectangle 90 */

  /*position: absolute;

left: 561px;
top: 1409px;
width: 385px;*/
  height: 463px;
  padding: 25px;
  margin-left: 60px;
 
}

.Card3 {
  /* Rectangle 91 */

  /*position: absolute;

left: 975px;
top: 1409px;*/
  width: 385px;
  height: 463px;
  padding: 30px;
  margin-left: 60px;
  
}

.Card4 {
  /* Rectangle 92 */
  /*position: absolute;

left: 1389px;
top: 1409px;*/
  width: 384px;
  height: 463px;
  padding: 30px;
  margin-left: 60px;
  
}

.heading3 {
  padding: 10px;
  color: #2e307b;
}

.heading4 {
  padding: 15px;
  color: #2e307b;
}

@media screen and (max-width: 320px) {
  .Card1 {
    /* Rectangle 89 */

    position: absolute;
    width: 320px;
    height: 400px;
    left: 147px;
    top: 1409px;
    font-size: 5vw;

    background: #ffffff;
  }
}

@media screen and (max-width: 320px) {
  .heading1 {
    color: #2e307b;
    font-size: 5vw;
  }
}

@media screen and (max-width: 320px) {
  .Card2 {
    /* Rectangle 90 */

    position: absolute;
    width: 320px;
    height: 420px;
    margin-top: 460px;
    left: 150px;
    font-size: 5vw;
    background: #ffffff;
  }
}

@media screen and (max-width: 320px) {
  .heading2 {
    padding: 10px;
    color: #2e307b;
    font-size: 5vw;
  }
}

@media screen and (max-width: 320px) {
  .Card3 {
    /* Rectangle 91 */

    position: absolute;
    width: 320px;
    height: 400px;
    margin-top: 900px;
    left: 150px;
    font-size: 5vw;
    background: #ffffff;
  }
}

@media screen and (max-width: 320px) {
  .heading3 {
    padding: 10px;
    color: #2e307b;
    font-size: 5vw;
  }
}

@media screen and (max-width: 320px) {
  .Card4 {
    /* Rectangle 92 */
    position: absolute;
    width: 320px;
    height: 400px;
    margin-top: 1400px;
    left: 150px;
    background: #ffffff;
  }
}

.core-features {
  display: flex;
}



@media(max-width:768px) {
  .core-features {
    display: block;
    margin-top: 40px;
    padding: 0 12px;
  }
  .Home_coreContainer__22mZa {
      padding: 0;
      padding-top: 30px;
  }

  .column {
    padding: 0 10px;
  }
}


ul li {
  font-family: Futura Md BT;
font-style: normal;
font-weight: normal;
font-size: 12.5px;
line-height: 19px;

}