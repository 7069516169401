.form-pic {
  /* position: absolute; */
  width: 1929px;
  height: 608px;
  left: 0px;
  top: 1074px;
  margin-top: 1000px;
  margin-left: 50px;
}

.cont1 {
  max-width: 1400px;
  margin: auto;
}

.column-6 {
  grid-column-end: 3;
  text-align: center;
  grid-column: 1 / -1;
  position: relative;
  top: -150px;
}

.column-5 {
  background: #ffffff;
  border: 1px solid #ededed;
  padding: 62px 44px;

  width: 100%;
  padding-top: 0;
  /* margin-right: 70px; */
  position: relative;
  top: -180px;
}

.column-pic {
  text-align: center;
  margin-top: -40px;
}

.column-5 a {
  display: block;
}

.column-card {
  margin-top: 54px;
}

.row-5 {
  display: grid;
  justify-content: center;
  width: 85%;
  margin: auto;

  grid-template-columns: repeat(4, 1fr);
  grid-gap: 31px;
  flex-wrap: wrap;
  margin-top: 250px;
  background: #ededed;
  padding: 0 92px;
}

.row-6 {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.column-7 {
  display: flex;
  margin-right: 30px;
  position: relative;
  width: 29%;
}

div.column-5:nth-child(3) {
  margin-right: 0;
}

.form-card1 {
  position: absolute;
  width: 261px;
  height: 313px;
  left: 456px;
  top: 256px;
  margin-top: 1000px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
}

.form-card2 {
  /* Rectangle 109 */

  position: absolute;
  width: 261px;
  height: 313px;
  left: 817px;
  top: 256px;
  margin-top: 1000px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
}

.form-card3 {
  /* Rectangle 92 */

  position: absolute;
  width: 260px;
  height: 313px;
  left: 1178px;
  top: 256px;
  margin-top: 1000px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
}

.elipse {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 546px;
  top: 216px;
  border-radius: 50%;
  margin-top: 980px;
  background: #ffffff;
  border: 1px solid #bebebe;
  box-sizing: border-box;
}

.form-head1 {
  /* position: absolute;
width: 174px;
height: 24px;
left: 499px;
top: 550px; */

  font-family: Futura Bk BT;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  color: #000000;
}

.form-pic1 {
  position: absolute;
  width: 1424px;
  height: 719px;
  left: 252px;
  top: 421px;
  margin-left: 38px;
  margin-top: 1000px;
  background: #ededed;
}

.form-pic2 {
  position: absolute;
  width: 261px;
  height: 313px;
  left: 456px;
  top: 256px;
  margin-left: 30px;
  margin-top: 1000px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
}

.form-pic3 {
  position: absolute;
  width: 261px;
  height: 313px;
  left: 817px;
  top: 256px;
  margin-left: 30px;
  margin-top: 1000px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
}

.form-pic4 {
  position: absolute;
  width: 260px;
  height: 313px;
  left: 1178px;
  top: 256px;
  margin-top: 1000px;
  margin-left: 30px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
}

.form-pic5 {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  margin-left: 570px;
  margin-top: 1220px;
  background: #ffffff;
  border: 1px solid #bebebe;
  box-sizing: border-box;
}

.form-circle {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 907px;
  top: 216px;
  border-radius: 50%;
  margin-left: 30px;
  margin-top: 1000px;
  background: #ffffff;
  border: 1px solid #bebebe;
  box-sizing: border-box;
}

.form-circle1 {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 1267px;
  top: 216px;
  margin-left: 30px;
  border-radius: 50%;
  margin-top: 1000px;
  background: #ffffff;
  border: 1px solid #bebebe;
  box-sizing: border-box;
}

.form-pic6 {
  position: absolute;
  width: 40px;
  height: 57px;
  margin: 18px;
  margin-right: 10px;
}

.form-pic7 {
  position: absolute;
  width: 41px;
  height: 42px;
  margin: 18px;
  margin-right: 10px;
}

.form-pic8 {
  position: absolute;
  width: 42px;
  height: 42px;
  margin: 18px;
  margin-right: 10px;
}

.form-head {
  /* position: absolute; */
  /* width: 174px; */
  text-align: center;
  height: 24px;
  left: -35px;
  top: 120px;
  margin-bottom: 19px;
  font-family: Futura Bk BT;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
}

.form-para {
  /* position: absolute; */
  width: 189px;
  /* height: 54px; */
  left: -35px;
  top: 160px;

  font-family: Futura Bk BT;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  color: #7e7e80;
}

.form-head1 {
  /* position: absolute; */
  /* width: 98px;
height: 18px;
left: 10px;
top: 250px; */

  font-family: Futura Md BT;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  margin-top: 43px;
  text-align: center;

  color: #2e307b;
}

.form-head2 {
  /* position: absolute; */
  /* width: 176px;
height: 24px; */
  left: -10px;
  top: 125px;
  text-align: center;
  font-family: Futura Bk BT;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 19px;
}

.form-para1 {
  /* /* position: absolute;

height: 18px; */
  width: 189px;
  left: -30px;
  top: 180px;
  margin-bottom: 80px;
  font-family: Futura Bk BT;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #7e7e80;
}

.form-head3 {
  /* position: absolute;
width: 65px;
height: 18px; */
  left: 10px;
  top: 250px;

  font-family: Futura Md BT;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #2e307b;
}

.form-head4 {
  /* position: absolute;
width: 176px;
height: 24px; */
  left: -28px;
  top: 120px;
  text-align: center;
  margin-bottom: 19px;
  font-family: Futura Bk BT;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  background-image: url();

  color: #000000;
}

.form-para2 {
  /* position: absolute;
/*
height: 18px; */
  width: 189px;
  left: -16px;
  top: 180px;
  margin-bottom: 80px;
  font-family: Futura Bk BT;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #7e7e80;
}

.form-head5 {
  /* position: absolute;
width: 52px;
height: 18px; */
  left: 20px;
  top: 260px;

  font-family: Futura Md BT;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #2e307b;
}

.form-head6 {
  /* position: absolute;
width: 300px;
height: 40px; */
  left: 859px;
  top: 619px;
  /* margin-top: 1000px; */
  font-family: Futura Hv BT;
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 30px;
  /* identical to box height */
  margin-top: 0;
  color: #2e307b;
}

.form-pic10 {
  position: absolute;
  width: 334px;
  height: 44px;
  top: -10px;
  margin-left: 20px;
}

.form-pic11 {
  position: absolute;

  height: 44px;
  top: -10px;

  margin-left: -10px;
}

.form-pic12 {
  position: absolute;
  /* width: 334px;
  height: 44px;
  top: -1px;
  margin-left: 40px; */
  bottom: -4px;
}

.form-pic13 {
  position: absolute;
  width: 47px;
  height: 49px;
  /* left: 609px;
  top: 839px;  */
  /* margin-top: 1040px; */
  top: -6px;
}

.column-8 {
  position: relative;
  margin-top: 20px;
}

.column-9 {
  margin-top: 152px;
}

.form-para3 {
  position: absolute;
  width: 260px;
  height: 26px;
  left: 666px;
  top: 857px;

  font-family: Futura Lt BT;
  font-style: italic;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  margin-top: 1040px;
  color: #7e7e80;
}

.form-border {
  position: absolute;
  width: 717px;
  height: 0px;
  left: 605px;
  top: 971px;
  margin-top: 1050px;
  border: 1px solid #7e7e80;
}

.form-col {
  /* position: absolute; */
  width: 288px;
  height: 96px;

  left: calc(50% - 288px / 2 - 15px);
  top: calc(50% - 66px / 2 + 242px);
  /* margin-top: 1350px; */
  background: #2e307b;
  left: 20px;
  /* position: absolute; */
  width: 288px;
  height: 51px;

  left: calc(50% - 230px / 2 - 15px);
  top: calc(50% - 21px / 2 + 242.5px);
  padding: 20px;
  font-family: Futura-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  color: #ffffff;

  mix-blend-mode: normal;
}

.maps > iframe:nth-child(1) {
  width: 100%;
  border: none;
}

.form-control {
  /* width: 300px; */
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  /* margin-left: 30px;
  margin-top: 20px; */
  font-size: 18px;
  padding-left: 50px;
  width: 300px;
}

.form-pic14 {
  position: absolute;
  /* width: 334px; */
  height: 44px;
  /* top: 5px;
  margin-left: 40px; */
}

.form-input {
  position: absolute;
  width: 334px;
  height: 44px;
  left: 446px;
  top: 702px;
  margin-top: 1011px;
  padding-left: 20px;
}

.form-input1 {
  position: absolute;
  width: 334px;
  height: 43px;
  left: 797px;
  top: 713px;
  margin-top: 1000px;
}

.form-input2 {
  position: absolute;
  width: 334px;
  height: 42px;
  left: 1179px;
  top: 714px;
  margin-top: 1000px;
  left: 1150px;
}

.form-input3 {
  position: absolute;
  width: 716px;
  height: 51px;
  left: 606px;
  top: 781px;
  margin-top: 1000px;
}

.form-input4 {
  position: absolute;
  width: 716px;
  height: 51px;
  left: 606px;
  top: 781px;
  margin-top: 1000px;
  margin-left: 340px;
}

.form-pic15 {
  position: absolute;
  /* width: 334px;
  height: 44px;
  top: 1px;
  margin-left: 40px; */
  bottom: -6px;
}

.text {
  /* position: absolute; */
  width: 500px;
  height: 140px;
  overflow: hidden;
  left: 666px;
  top: 857px;
  /* margin-top: 1035px; */
  font-family: Futura Lt BT;
  font-style: italic;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  border: none;
  border-bottom: 1px solid;
  margin-left: 60px;
  outline: none;
  background-color: #ededed;
  resize: none;
  color: #7e7e80;
}

input,
textarea {
  background-color: #ededed;

  padding: 9px;
}

.auto {
  cursor: auto;
}

@media (max-width: 768px) {
  .row-5 {
    padding: 0 25px;
    width: 100%;
    display: block;
  }

  .column-5 {
    width: 80%;
    margin: auto;
    margin-bottom: 73px;
  }

  .form-para {
    margin: auto;
  }

  .form-para1 {
    margin: auto;
  }

  div.column-5:nth-child(3) {
    margin-right: auto;
  }

  .form-para2 {
    margin: auto;
  }

  .form-head {
    height: auto;
  }

  .row-6 {
    flex-direction: column;
    align-items: center;
  }

  .column-7 {
    margin-right: 0;
    margin-bottom: 31px;
    width: auto;
  }

  .text {
    width: 100%;
    padding-left: 50px;
    margin-left: 0;
  }

  .column-8 {
    width: 100%;
  }
}


@media (max-width: 991px) and (min-width: 768px) {
  .column-8 {
    width: 50% !important;
  }
}