
.vision-mission {
    padding: 0 186px;
    }

    
.head5 {
    /* OUR VISION */

 
/*position: absolute; */
width: auto;
height: auto;
left: 90px;
top: 205px;
margin-top: 60px;
font-family: Futura-Bold;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 46px;
display: flex;
align-items: center;
letter-spacing: 0.06em;
margin-left: 0;
color: #2E307B;
}


.para2 {
/* position: absolute; */
width: auto;
height: auto;
left: 151px;
top: 280px;
/* margin-top: 2200px; */
font-family: Futura Bk BT;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 24px;
text-align: justify;
letter-spacing: 0.06em;

color: #000000;

}


.head2 {
    /* OUR MISSION */


/* position: absolute; */
width: auto;
height: auto;
left: 150px;
top: 455px;
margin-top: 60px;
font-family: Futura-Bold;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 46px;
display: flex;
align-items: center;
letter-spacing: 0.06em;

color: #2E307B;
}


.para3 {
/* position: absolute; */
width: auto;
height: auto;
left: 155px;
top: 476px;
/* margin-top: 2250px; */
font-family: Futura Bk BT;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 24px;
text-align: justify;
letter-spacing: 0.06em;

color: #000000;
}

@media (max-width: 768px) {
    .vision-mission {
        padding: 0 30px !important;
        }
}