.legend {
    width: 100%;
  
    height: 800px;
    background-image: url("../../Assets/legend.png");
    display: flex;
    align-items: center;
    justify-content: flex-start;
  
    @media (max-width: 500px) {
      height: 500px;
    }
  
    @media (max-width: 900px) {
      justify-content: center;
    }
  }

  .legendHeading {
    font-size: 50px;
    color: white;
    line-height: 55px;
    margin-left: 200px;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    margin-bottom: 0px;
  
    @media (max-width: 400px) {
      font-size: 20px;
      line-height: 25px;
    }
  
    @media (max-width: 500px) {
      font-size: 30px;
      line-height: 35px;
    }
  
    @media (max-width: 700px) {
      font-size: 40px;
      line-height: 45px;
    }
  
    @media (max-width: 900px) {
      margin-left: 0px;
      text-align: center;
    }
  }

  .legenSubHeading {
    color: white;
    margin-left: 210px;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    margin-top: 10px;
    font-size: 15px;
  
    @media (max-width: 900px) {
      margin-left: 0px;
      text-align: center;
    }
  }
  