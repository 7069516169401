.legend {
  width: 100%;

  height: 800px;
  background-image: url("../../Assets/legend.png");
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 500px) {
    height: 500px;
  }

  @media (max-width: 900px) {
    justify-content: center;
  }
}

.legendHeading {
  font-size: 50px;
  color: white;
  line-height: 55px;
  margin-left: 200px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin-bottom: 0px;
  display: flexbox;
  @media (max-width: 400px) {
    font-size: 20px;
    line-height: 25px;
  }

  @media (max-width: 500px) {
    font-size: 30px;
    line-height: 35px;
  }

  @media (max-width: 700px) {
    font-size: 40px;
    line-height: 45px;
  }

  @media (max-width: 900px) {
    margin-left: 0px;
    text-align: center;
  }
}

.legenSubHeading {
  color: white;
  margin-left: 210px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin-top: 10px;
  font-size: 15px;
  
  @media (max-width: 900px) {
    margin-left: 0px;
    text-align: center;
  }
}

.coreContainer {
  padding: 3rem 25px;
  background-color: #ededed;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.Flexouter {
  text-align: center;
}



.coreHeading {
  font-family: Futura-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: -1px;
  display: flex;
  align-items: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 0px;
  color: #7E7E80;
}

.coreHeadingBorder {
  width: 100px;
  height: 2px;
  background-color: #7e7280;
  margin-top: 10px;
}

.Border {

  width: 90%;
  height: 2px;
  background-color: #000;
  margin: 37px auto 12px;

}

.span {
/* Terms & Conditions */


// position: absolute;
width: 215px;
height: 24px;
left: 1035px;
top: 3778px;

font-family: Futura Hv BT;
font-style: normal;
font-weight: 100;
font-size: 13px;
line-height: 24px;
/* identical to box height */


color: #000000;

mix-blend-mode: normal;
}

.para {
  /* Privacy Policy */


// position: absolute;
margin-right: 10px;
height: 24px;
left: 862px;
top: 3778px;

font-family: Futura Hv BT;
font-style: normal;
font-weight: 100;
font-size: 13px;
line-height: 24px;
/* identical to box height */


color: #000000;

mix-blend-mode: normal;
}

.phot {

  /* Ellipse 8 */


// position: absolute;
width: 14px;
height: 13px;
left: 1009px;
top: 3784px;
margin-top: 5px;
margin-right: 10px;

}

.container1 {
  display: flex;
  flex-direction: column;
}

@media (max-width: 320px) {
  .coreHeading {
    font-size: 35px;
    font-weight: 100;
    font-size: 10vw; 
    line-height: -1px;
    letter-spacing: 0.06em;
    color: #7e7280;
    height: 500px;
    margin: 0px;
    padding: 0px;
    margin-left: 80px;
  }
}



@media (max-width: 320px) {
.coreHeadingBorder {
  width: 100px;
  height: 270px;
  background-color: #7e7280;
  margin-top: 10px;
  margin-left: 70px;
  
}
}

.Flexinner {
  display: block;
  // justify-content: center;
}